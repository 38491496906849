/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

//hides ugly shadow for nav items in main menu
[dir] .main-menu.menu-light .navigation > li.active > a {
  box-shadow: none;
}
[dir] .main-menu.menu-dark .navigation > li.active > a {
  box-shadow: none;
}

//compacts tables
[dir] .compacted.table > :not(caption) > * > * {
  padding: 0.3rem 0.5rem;
}

//used for centering cards
.card-centered-full, .card-centered-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-centered-full {
  align-items: center;
}

//drop animation for mails app, pointless loss of performance
[dir=ltr] .email-application .content-area-wrapper .email-user-list .email-media-list li:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
[dir=ltr] .email-application .content-area-wrapper .email-user-list .email-media-list li {
  -webkit-animation: none!important;
  animation: none!important;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

//ask vacation extras
.vacation-status-align {
  flex: 1;
}
.card-vacation-status .avatar {
  width: 32px;
  height: 32px;
}
[dir] .card-vacation-status .card-img-wrapper-default {
  background-color: rgba(115,103,240,.1);
}

//drop shadow for theme elements
[dir] .header-navbar.navbar-shadow {
  box-shadow: none;
  border-bottom: 1px solid #ebe9f1;
}
[dir] .dark-layout .header-navbar {
  border-bottom: 1px solid #3b4253;
}
[dir] .main-menu.menu-light .navigation > li ul .active, [dir] .main-menu.menu-dark .navigation > li ul .active {
  box-shadow: none;
}
[dir] .card {
  box-shadow: none;
  border: 1px solid #ebe9f1;
}

[dir] .dark-layout .card {
  border: 1px solid #3b4253;
}


//additional mail styles
[dir=ltr] .email-application .content-area-wrapper .email-user-list .email-media-list li.mail-asks-confirmation {
  border-left: 3px solid #e00!important;
}

.email-application .content-area-wrapper .email-user-list {
  height: calc( 100% )!important;
}

@media (max-width: 767.98px) {
  //gotta account for the 2nd navbar in mobile
  .email-application .content-area-wrapper .email-user-list {
    height: calc( 100% - 3.8rem)!important;
  }
}

@media (max-width: 575.98px) {
  .email-application .content-area-wrapper .email-user-list .email-media-list li .mail-details .mail-meta-item {
    width: 20%!important;
    text-align: right;
  }
}

//additional mail styles
[dir=ltr] .email-application .content-area-wrapper .email-user-list .email-media-list li.mail-confirmed {
  border-left: 3px solid #52b10f!important;
}
.dark-layout .fallback-spinner.app-loader {
  background-color: #283046;
}
[dir] .dark-layout .email-application .email-app-details {
  background-color: #283046;
}
//this allows to remove useless vertical scrolling for some apps in mobile
@media (max-width: 767.98px) {
  html[dir] body .app-content {
    padding-top: calc(2rem - 0.8rem + 4.45rem) !important;
  }
}

// ------CALENDAR--------
[dir] .dark-layout .app-calendar .fc thead th {
  background: #283046;
}

@media (max-width: 767.98px) {
  [dir] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button, [dir] .fc .fc-toolbar .fc-button-group .fc-listMonth-button {
    padding: 0.55rem 0.8rem!important;
  }
  //squeeze into tight mobile screen
  [dir] .fc-timegrid-event .fc-event-time {
    white-space: normal;
  }
  [dir] .fc .fc-daygrid-day {
    padding: 0!important;
  }
  [dir] .fc .fc-daygrid-event-harness .fc-event {
    padding-left: 1px!important;
    padding-right: 0!important;
  }
  [dir=ltr] .fc-direction-ltr .fc-daygrid-event .fc-event-time {
    white-space: normal;
  }

  .fc-daygrid-dot-event .fc-event-title {
    display: none;
  }
  .fc-h-event .fc-event-title-container {
    display: none;
  }

  [dir] .fc-daygrid-event-dot {
    display: none;
  }
}

